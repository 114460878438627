<template>
  <div class="container">
    <div class="row justify-content-center py-5">
      <div class="col-lg-8 col-12 text-justify bg-light p-4 rounded">
        <h2 class="text-center text-uppercase">Terminos y condiciones</h2>
        <div class="w-100 py-2"></div>
        <p>
          Este contrato describe los términos y condiciones generales (los
          "Términos y Condiciones Generales") aplicables al uso de los servicios
          ofrecidos por TicketApp ("los Servicios") dentro del sitio principal
          pedidos.ticketapp.cl ("Pedidos de TicketApp", el "sitio" o
          "plataforma"), u otro dominio personalizado de las empresas que apunte
          a este sitio. Cualquier persona que desee acceder y/o usar el sitio o
          los servicios podrá hacerlo sujetándose a los Términos y Condiciones
          Generales, junto con todas las demás políticas y principios que rigen
          Pedidos de TicketApp y que son incorporados al presente por
          referencia.
        </p>
        <p class="font-weight-bold">
          TODA PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS
          CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE
          DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Definiciones</h4>
        <p>
          <strong>Empresa</strong> es el comercio responsable que usa los
          servicios de la plataforma. Ajenos a TicketApp o cualquier servicio
          relacionado con la plataforma
          <br />
          <strong>Plataforma</strong> es el sitio web automatizado ofrecido por
          TicketApp para que las empresas vendan productos a sus clientes
          <br />
          <strong>Clientes</strong> es el usuario que solicita los servicios de
          la empresa mediante de la plataforma
          <br />
          <strong>Pedidos</strong> son las ordenes que reciben las empresas de
          los clientes
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Uso de la plataforma</h4>
        <p>
          El usuario debe leer, entender y aceptar todas las condiciones
          establecidas en los Términos y Condiciones Generales y en las
          Políticas de Privacidad así como en los demás documentos incorporados
          a los mismos por referencia, previo a su inscripción como Usuario de
          la plataforma. Las empresas son responsables del contenido, imagenes,
          productos, servicios ofrecidos en la plataforma.
        </p>

        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Inscripción</h4>
        <p>
          Los Servicios sólo están disponibles para personas que tengan
          capacidad legal para contratar. No podrán utilizar los servicios las
          personas que no tengan esa capacidad, los menores de edad o Usuarios
          de la plataforma que hayan sido suspendidos temporalmente o
          inhabilitados definitivamente.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Pagos en linea</h4>
        <p>
          Los pagos en linea sólo están disponibles para empresas que contraten
          este servicio. Para solicitar una anulación o reembolso, debe ser
          gestionado por la empresa responsable del pedido.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Uso de datos</h4>
        <p>
          Los datos proporcionados seran usados exclusivamente para la ejecucion
          del pedido del cliente con la empresa correspondiente. Los datos
          recolectados serán utilizados para fines estadisticos y logisticos
          para la plataforma. Las empresas se comprometen al correcto uso de los
          datos. La plataforma no se hace responsable por el mal uso de los
          datos que las empresas le puedan dar.
          <br />
          Para el correcto funcionamiento de la plataforma, esta utiliza
          servicios de terceros para la recolección de datos y estos sólo tienen
          finen estadistico. La plataforma no se hace responsable por el mal uso
          de terceros.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Privacidad de los usuarios</h4>
        <p>
          Para utilizar los servicios ofrecidos por la plataforma, los usuarios
          deberán facilitar determinados datos de carácter personal. Su
          información personal se procesa y almacena en servidores o medios
          magnéticos que mantienen altos estándares de seguridad y protección
          tanto física como tecnológica. Esta información esta disponible solo
          para la empresa responsable del pedido.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Responsabilidad de los pedidos</h4>
        <p>
          La entrega de los pedidos realizados no conforma ninguna obligacion
          por parte de la plataforma. El cumplimiento es responsabilidad de la
          empresa que contrata el servicio con la plataforma. En caso de haber
          existir algun incoveniente, estos deben ser resultos por la empresa
          correspondiente.
          <br />
          Pedidos de TicketApp se exime de toda responsabilidad de los pedidos.
        </p>
        <div class="w-100 py-2"></div>
        <h4 class="text-uppercase">Modificaciones del Acuerdo</h4>
        <p>
          La plataforma podrá modificar los Términos y Condiciones Generales en
          cualquier momento haciendo públicos en el Sitio los términos
          modificados. Todos los términos modificados entrarán en vigor a los 10
          (diez) días de su publicación. Dichas modificaciones serán comunicadas
          a los usuarios que en la Configuración de su Cuenta hayan indicado que
          desean recibir notificaciones de los cambios en estos Términos y
          Condiciones. Todo usuario que no esté de acuerdo con las
          modificaciones efectuadas, podrá solicitar la baja de la cuenta.
        </p>

        <div class="w-100 py-4"></div>
        <p class="font-weight-light">Ultima modificacion: 24/06/2022</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.scroll(0, 0)
    this.$gtag.event("view_tos", {
      event_category: "user",
      event_label: "View tos page",
      value: 1,
    });
  },
};
</script>
